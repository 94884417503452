'use client';

import { TooltipProvider } from '@askable/ui/core/tooltip';
import { ThemeProvider, useTheme } from 'next-themes';
import { useEffect } from 'react';

import type { ReactNode } from 'react';

type Theme = 'dark' | 'light';
interface Props {
  children: ReactNode;
  storageKey?: 'next-theme-global' | 'next-theme-insight';
  defaultTheme?: Theme;
}

const NextThemeProviderInner = ({ children, defaultTheme }: Pick<Props, 'children' | 'defaultTheme'>) => {
  const { setTheme } = useTheme();
  useEffect(() => {
    if (!defaultTheme) {
      return;
    }
    // When passing defaultTheme (or even forcedTheme), it actually doesn't update localStorage.
    // It causing the issue when localStorage value is something invalid (e.g. 'undefined').
    // defaultTheme doesn't work anymore as it prioritizes localStorage value.
    // Therefore, we always update localStorage value with the defaultTheme to make sure it always works.
    setTheme(defaultTheme);
  }, [defaultTheme, setTheme]);

  return children;
};

export const NextThemeProvider = ({ children, storageKey = 'next-theme-global', defaultTheme = 'dark' }: Props) => {
  return (
    <ThemeProvider storageKey={storageKey} attribute="class" defaultTheme={defaultTheme}>
      <TooltipProvider>
        <NextThemeProviderInner defaultTheme={defaultTheme}>{children}</NextThemeProviderInner>
      </TooltipProvider>
    </ThemeProvider>
  );
};
